import { accordion } from "./modules/accordion";
import { gaEvents } from "./modules/gaEvents";
import { typeText } from "./modules/typeText";
import { videoModal } from "./modules/videoModal";

document.addEventListener("DOMContentLoaded", function () {
  accordion();
  gaEvents();
  typeText();
  videoModal();
});
