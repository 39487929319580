export function accordion() {
  const accordionHeader = document.querySelectorAll(`[data-accordion-header]`);
  if (accordionHeader.length) {
    const accordionToggle = (e) => {
      let expanded = e.target.getAttribute("aria-expanded") === "true" || false;
      const panel = e.target.nextElementSibling;
      e.target.setAttribute("aria-expanded", !expanded);
      panel.setAttribute("aria-hidden", expanded);
    };

    accordionHeader.forEach((el) =>
      el.addEventListener("click", accordionToggle)
    );
  }
}
