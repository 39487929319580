import Typed from "typed.js";

export function typeText() {
  const displayTime = () => {
    const timer = new Date();
    return timer.toLocaleTimeString([], { timeStyle: "short" });
  };

  const headline = document.querySelector(".headline span");

  if (headline) {
    const question = document
      .querySelector(".headline")
      .getAttribute("data-question");
    const options = {
      strings: [
        `Congratulations, at ${displayTime()} you took your first steps to Get Ahead of AI in Financial Services.^1500`,
        `To customise this experience, we have one question for you...^1500`,
        `${question}?^1000`,
      ],
      typeSpeed: 35,
      fadeOut: true,
      onComplete: () => {
        textCompleted();
      },
    };

    const typed = new Typed(headline, options);

    const textCompleted = () => {
      document.querySelector(".typed-cursor").style.display = "none";
      document.querySelector(".navigation").classList.add("is-active");
    };
  }
}
