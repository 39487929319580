export function videoModal() {
  const modalOpener = document.querySelectorAll(".js-modal-open");
  if (modalOpener.length) {
    const modal = document.querySelector(".js-modal");
    const modalCloser = document.querySelector(".js-modal-closer");
    const iframe = modal.querySelector("iframe");

    const modalOpen = (e) => {
      iframe.src = e.target.getAttribute("data-modal-url");
      modal.classList.add("active");
    };

    const modalClose = (e) => {
      iframe.src = "";
      modal.classList.remove("active");
    };

    modalOpener.forEach((el) => el.addEventListener("click", modalOpen));
    modalCloser.addEventListener("click", modalClose);
    modal.addEventListener("click", modalClose);
  }
}
