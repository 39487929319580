export function gaEvents() {
  const trackingLinks = document.querySelectorAll(`[data-ga-label]`);
  if (trackingLinks.length) {
    var _gaq = _gaq || [];
    _gaq.push(["_setAccount", "UA-206509308-2"]);
    _gaq.push(["_trackPageview"]);

    (function () {
      var ga = document.createElement("script");
      ga.type = "text/javascript";
      ga.async = true;
      ga.src =
        ("https:" == document.location.protocol
          ? "https://ssl"
          : "http://www") + ".google-analytics.com/ga.js";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(ga, s);
    })();

    const trackEvent = (e) => {
      console.log("tracked");
      var category = e.target.getAttribute("data-ga-category");
      var action = e.target.getAttribute("data-ga-action");
      var label = e.target.getAttribute("data-ga-label");
      var value = e.target.getAttribute("data-ga-value");
      console.log(label);
      _gaq.push(["_trackEvent", category, action, label, value]);
    };
    trackingLinks.forEach((el) => el.addEventListener("click", trackEvent));
  }
}
